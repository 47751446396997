import React, { useState, useEffect } from 'react';
import './css/legalDisclaimer.css'
import './css/fswp.css'
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import FSWPScoreCalculator from './fswpScoreCalculator.js';
import LegalDisclaimerFswp from './legalDisclaimerFswp.js';

const FSWPTool = () => {
    const { t } = useTranslation();

    const [showPopup, setShowPopup] = useState(false);

    const handleButtonClick = () => {
      setShowPopup(true);
    };
  
    const handleCloseClick = () => {
      setShowPopup(false);
    };

    const [languageTest, setLanguageTest] = useState('');
    const [showLanguageTestQuestion, setShowLanguageTestQuestion] = useState(false);
    const [testPoints, setTestPoints] = useState({ speaking: [], listening: [], reading: [], writing: [] });

    const handleLanguageTest = (event) => {
        const selectedLanguageTest = event.target.value;
        const selectedLanguageTestLabel = languageTestOptions.find(option => option.value === selectedLanguageTest)?.label;
        setLanguageTest(selectedLanguageTest);
        setShowLanguageTestQuestion(true);

        axios.get(`https://northern-pathways-backend.onrender.com/api/languageTest/${selectedLanguageTestLabel}`)
            .then(response => {
                console.log('TestPoints Response:', response);
                setTestPoints(response.data.testPoints);
            })
            .catch(error => {
                console.error('Error fetching testPoints:', error);
            });
    };
    
    const [speakingTest, setSpeakingTest] = useState('');
    const [listeningTest, setListeningTest] = useState('');
    const [readingTest, setReadingTest] = useState('');
    const [writingTest, setWritingTest] = useState('');

    const handleSpeaking = (event) => {
        const selectedSpeaking = event.target.value;
        setSpeakingTest(selectedSpeaking);
    }

    const handleListening = (event) => {
        const selectedListening = event.target.value;
        setListeningTest(selectedListening);
    }

    const handleReading = (event) => {
        const selectedReading = event.target.value;
        setReadingTest(selectedReading);
    }

    const handleWriting = (event) => {
        const selectedWriting = event.target.value;
        setWritingTest(selectedWriting);
    }

    const checkAndSetShowSecondLanguageTest = () => {
        if (speakingTest !== '' && listeningTest !== '' && readingTest !== '' && writingTest !== '') {
          setShowSecondLanguageTest(true);
        } else {
          setShowSecondLanguageTest(false);
        }
    }

    useEffect(() => {
        setSpeakingTest('');
        setListeningTest('');
        setReadingTest('');
        setWritingTest('');
      }, [languageTest]);

    useEffect(() => {
        checkAndSetShowSecondLanguageTest();
    }, [speakingTest, listeningTest, readingTest, writingTest, languageTest]);

    const [speakingTestSecond, setSpeakingTestSecond] = useState('');
    const [listeningTestSecond, setListeningTestSecond] = useState('');
    const [readingTestSecond, setReadingTestSecond] = useState('');
    const [writingTestSecond, setWritingTestSecond] = useState('');  

    const [secondLanguageTest, setSecondLanguageTest] = useState('');
    const [showSecondLanguageTest, setShowSecondLanguageTest] = useState(false);
    const [showSecondLanguageTestQuestion, setShowSecondLanguageTestQuestion] = useState(false);  
    const [secondTestPoints, setSecondTestPoints] = useState({ speaking: [], listening: [], reading: [], writing: [] });

    const handleSecondLanguageTest = (event) => {
        const selectedSecondLanguageTest = event.target.value;
        const selectedSecondLanguageTestLabel = languageTestOptions.find(option => option.value === selectedSecondLanguageTest)?.label;
        setSecondLanguageTest(selectedSecondLanguageTest);
    
        axios.get(`https://northern-pathways-backend.onrender.com/api/languageTest/${selectedSecondLanguageTestLabel}`)
          .then(response => {
            console.log('SecondTestPoints Response:', response);
            setSecondTestPoints(response.data.testPoints);
          })
          .catch(error => {
            console.error('Error fetching testPoints:', error);
          });
    
        if (selectedSecondLanguageTest !== 'option6') {
          setShowSecondLanguageTestQuestion(true);
        } else if (selectedSecondLanguageTest === 'option6') {
          setShowSecondLanguageTestQuestion(false);
        }
      };
    
      const handleSecondSpeaking = (event) => {
        const selectedSpeaking = event.target.value;
        setSpeakingTestSecond(selectedSpeaking);
      }
      
      const handleSecondListening = (event) => {
        const selectedListening = event.target.value;
        setListeningTestSecond(selectedListening);
      }
      
      const handleSecondReading = (event) => {
        const selectedReading = event.target.value;
        setReadingTestSecond(selectedReading);
      }
      
      const handleSecondWriting = (event) => {
        const selectedWriting = event.target.value;
        setWritingTestSecond(selectedWriting);
      }
    
      const checkSecondLanguageTest = () => {
        if (speakingTestSecond !== '' && listeningTestSecond !== '' && readingTestSecond !== '' && writingTestSecond !== '') {
          setShowEducationStatusQuestion(true);
        } else if (secondLanguageTest === 'option6'){
          setSpeakingTestSecond('')
          setListeningTestSecond('')
          setReadingTestSecond('')
          setWritingTestSecond('')
          setShowEducationStatusQuestion(true);
        } else {
          setShowEducationStatusQuestion(false);
        }
      }

      useEffect(() => {
        setSpeakingTestSecond('');
        setListeningTestSecond('');
        setReadingTestSecond('');
        setWritingTestSecond('');
      }, [secondLanguageTest]);

      useEffect(() => {
        checkSecondLanguageTest();
      }, [speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, secondLanguageTest]);
    

    const rearrangeOptions = (options, startOption) => {
        return options.sort((a, b) => {
            const valueA = parseInt(a.value.replace('option', ''));
            const valueB = parseInt(b.value.replace('option', ''));
    
            if (valueA === startOption) {
                return -1; // Move 'a' to a lower index if its value matches the startOption
            } else if (valueB === startOption) {
                return 1; // Move 'b' to a lower index if its value matches the startOption
            } else {
                return valueA - valueB; // Otherwise, sort normally
            }
        });
    };

    const [languageStatusOptions, setLanguageStatusOptions] = useState([]);
    const [languageTestOptions, setLanguageTestOptions] = useState([]);
    const [spouseLanguageTestOptions, setSpouseLanguageTestOptions] = useState([]);

    const [educationOptions, setEducationOptions] = useState([]);
    const [spouseEducationStatusOptions, setSpouseEducationStatusOptions] = useState([]);
    const [educationStatus, setEducationStatus] = useState('');
    const [showEducationStatusQuestion, setShowEducationStatusQuestion] = useState(false);

    const handleEducationStatus = (event) => {
        const selectedEducationStatus = event.target.value;
        setEducationStatus(selectedEducationStatus);

        if (selectedEducationStatus !== ''){
            setShowFSWPWorkStatusQuestion(true);
        }
      };
    
    const [FSWPWorkStatus, setFSWPWorkStatus] = useState('');
    const [FSWPWorkOptions, setFSWPWorkOptions] = useState([]);
    const [showFSWPWorkStatusQuestion, setShowFSWPWorkStatusQuestion] = useState(false);

    const handleFSWPWorkStatus = (event) => {
        const selectedFSWPWorkStatus = event.target.value;
        setFSWPWorkStatus(selectedFSWPWorkStatus);

        if (selectedFSWPWorkStatus !== '') {
            setShowFSWPAgeStatusQuestion(true);
        }
    };

    const [FSWPAgeOptions, setFSWPAgeOptions] = useState([]);
    const [FSWPAgeStatus, setFSWPAgeStatus] = useState('');
    const [showFSWPAgeStatusQuestion, setShowFSWPAgeStatusQuestion] = useState(false);

    const handleFSWPAgeStatus = (event) => {
        const selectedFSWPAgeStatus = event.target.value;
        setFSWPAgeStatus(selectedFSWPAgeStatus);

        if (selectedFSWPAgeStatus !== '') {
            setShowAdditionalStatusQuestion(true);
        }
    };

    const [additionalStatusOptions, setAdditionalStatusOptions] = useState([]);
    const [additionalStatus, setAdditionalStatus] = useState('');
    const [showAdditionalStatusQuestion, setShowAdditionalStatusQuestion] = useState(false);
  
    const handleAdditionalStatus = (event) => {
        const selectedAdditionalStatus = event.target.value;
        setAdditionalStatus(selectedAdditionalStatus);

        if (selectedAdditionalStatus !== '') {
            setShowCaEducationStatusQuestion(true);
            setShowTitle(true);
        }
    };

    const [caEducationStatus, setCaEducationStatus] = useState('');
    const [caEducationOptions, setCaEducationOptions] = useState([]);
    const [showCaEducationStatusQuestion, setShowCaEducationStatusQuestion] = useState(false);
    const [showTitle, setShowTitle] = useState(false);

    const handleCaEducationStatus = (event) => {
        const selectedCaEducationStatus = event.target.value;
        setCaEducationStatus(selectedCaEducationStatus);

        if (selectedCaEducationStatus !== '') {
            setShowWorkStatusQuestion(true);
        }
    };

    const [workStatus, setWorkStatus] = useState('');
    const [workStatusOptions, setWorkStatusOptions] = useState([]);
    const [showWorkStatusQuestion, setShowWorkStatusQuestion] = useState(false);
  
    const handleWorkStatus = (event) => {
      const selectedWorkStatus = event.target.value;
      setWorkStatus(selectedWorkStatus);

      if (selectedWorkStatus !== '') {
        setShowMaritalStatusQuestion(true);
      }
    };

    const [maritalStatus, setMaritalSatus] = useState('');
    const [maritalOptions, setMaritalOptions] = useState([]);
    const [showMaritalStatusQuestion, setShowMaritalStatusQuestion] = useState(false);
  
    const handleMaritalChange = (event) => {
        const selectedMaritalOption = event.target.value;
        setMaritalSatus(selectedMaritalOption);

        if(selectedMaritalOption === 'option2' || selectedMaritalOption === 'option3'){
            setShowSpouseQuestion(true);
        } else {
            setShowSpouseQuestion(false);
            setShowLawPartnerStatusQuestion(true);
        }
    };

    const [partnerStatus, setPartnerStatus] = useState('');
    const [showSpouseQuestion, setShowSpouseQuestion] = useState(false);
    const [partnerOptions, setPartnerOptions] = useState([]);

    const handlePartnerStatus = (event) => {
      const selectedPartnerStatus = event.target.value;
      setPartnerStatus(selectedPartnerStatus);

      if (selectedPartnerStatus === 'option2') {
        setShowSpouseMovingQuestion(false)
        setShowSpouseEducationStatus(false);
        setSpouseWorkStatus(false);
        setShowSpouseLanguageTest(false);
        setShowSpouseLanguageTestQuestion(false);

        setSpeakingTestSpouse('');
        setListeningTestSpouse('');
        setWritingTestSpouse('');
        setReadingTestSpouse('');

        setShowLawPartnerStatusQuestion(true);
      } else {
        setShowSpouseMovingQuestion(true);
      }
    };

    const [movingStatus, setMovingStatus] = useState('');
    const [partnerMovingOptions, setPartnerMovingOptions] = useState([]);
    const [showSpouseMovingQuestion, setShowSpouseMovingQuestion] = useState(false);

    const handleMovingStatus = (event) => {
      const selectedMovingStatus = event.target.value;
      setMovingStatus(selectedMovingStatus);

      if (selectedMovingStatus === 'option2') {
        setShowSpouseEducationStatus(true);
      } else {
        setShowSpouseEducationStatus(false);
        setSpouseWorkStatus(false);
        setShowSpouseLanguageTest(false);
        setShowSpouseLanguageTestQuestion(false);

        setSpeakingTestSpouse('');
        setListeningTestSpouse('');
        setWritingTestSpouse('');
        setReadingTestSpouse('');

        setShowLawPartnerStatusQuestion(true);
      }
    };

    const [spouseEducationStatus, setSpouseEducationStatus] = useState('');
    const [showSpouseEducationStatus, setShowSpouseEducationStatus] = useState(false);

    const handleSpouseEducationStatus = (event) => {
        const selectedSpouseEducationStatus = event.target.value;
        setSpouseEducationStatus(selectedSpouseEducationStatus);

        if (selectedSpouseEducationStatus !== '') {
            setShowSpouseWorkStatus(true);
        }
    };

    const [spouseWorkStatus, setSpouseWorkStatus] = useState('');
    const [spouseWorkStatusOptions, setSpouseWorkStatusOptions] = useState([]);
    const [showSpouseWorkStatus, setShowSpouseWorkStatus] = useState(false);

    const handleSpouseWorkStatus = (event) => {
        const selectedSpouseWorkStatus = event.target.value;
        setSpouseWorkStatus(selectedSpouseWorkStatus);

        if (selectedSpouseWorkStatus !== '') {
            setShowSpouseLanguageTest(true);
        }
    };

    const [spouseLanguageTest, setSpouseLanguageTest] = useState('');
    const [spouseTestPoints, setSpouseTestPoints] = useState([]);
    const [showSpouseLanguageTest, setShowSpouseLanguageTest] = useState(false);
    const [showSpouseLanguageTestQuestion, setShowSpouseLanguageTestQuestion] = useState(false);


    const handleSpouseLanguageTest = (event) => {
        const selectedSpouseLanguageTest = event.target.value;
        const selectedSpouseLanguageTestLabel = languageTestOptions.find(option => option.value === selectedSpouseLanguageTest)?.label;
        setSpouseLanguageTest(selectedSpouseLanguageTest);
    
        axios.get(`https://northern-pathways-backend.onrender.com/api/languageTest/${selectedSpouseLanguageTestLabel}`)
          .then(response => {
            console.log('SpouseTestPoints Response:', response);
            setSpouseTestPoints(response.data.testPoints);
          })
          .catch(error => {
            console.error('Error fetching testPoints:', error);
          });
        
        if (selectedSpouseLanguageTest === 'option6') {
            setShowSpouseLanguageTestQuestion(false);
            setShowLawPartnerStatusQuestion(true);
        } 
        
        if (selectedSpouseLanguageTest === 'option1' || selectedSpouseLanguageTest === 'option2' || selectedSpouseLanguageTest === 'option3' || selectedSpouseLanguageTest === 'option4' || selectedSpouseLanguageTest === 'option5'){
            setShowSpouseLanguageTestQuestion(true);
        }
    };

    const [speakingTestSpouse, setSpeakingTestSpouse] = useState('');
    const [listeningTestSpouse, setListeningTestSpouse] = useState('');
    const [readingTestSpouse, setReadingTestSpouse] = useState('');
    const [writingTestSpouse, setWritingTestSpouse] = useState('');

    useEffect(() => {
        if (speakingTestSpouse !== '' && listeningTestSpouse !== '' && readingTestSpouse !== '' && writingTestSpouse !== '') {
            setShowLawPartnerStatusQuestion(true);
        }
    }, [speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse])

    const handleSpouseSpeaking = (event) => {
        const selectedSpeaking = event.target.value;
        setSpeakingTestSpouse(selectedSpeaking);
    }

    const handleSpouseListening = (event) => {
        const selectedListening = event.target.value;
        setListeningTestSpouse(selectedListening);
    }

    const handleSpouseReading = (event) => {
        const selectedReading = event.target.value;
        setReadingTestSpouse(selectedReading);
    }

    const handleSpouseWriting = (event) => {
        const selectedWriting = event.target.value;
        setWritingTestSpouse(selectedWriting);
    }

    useEffect(() => {
        setSpeakingTestSpouse('');
        setListeningTestSpouse('');
        setReadingTestSpouse('');
        setWritingTestSpouse('');
      }, [spouseLanguageTest]);

    const [lawPartnerStatus, setLawPartnerStatus] = useState('');
    const [lawPartnerStatusOptions, setLawPartnerStatusOptions] = useState([]);
    const [showLawPartnerStatusQuestion, setShowLawPartnerStatusQuestion] = useState(false);
  
    const handleLawPartnerStatus = (event) => {
        const selectedLawPartnerStatus = event.target.value;
        setLawPartnerStatus(selectedLawPartnerStatus);
    };

    useEffect(() => {
        const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en'; 
        const languageStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/languageStatusTR' : 'https://northern-pathways-backend.onrender.com/api/languageStatus';
        const languageTestEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/languageTestTR' : 'https://northern-pathways-backend.onrender.com/api/languageTest';
        const educationEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/educationStatusTR' : 'https://northern-pathways-backend.onrender.com/api/educationStatus';
        const fswpWorkStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/fswpWorkStatusTR' : 'https://northern-pathways-backend.onrender.com/api/fswpWorkStatus';
        const fswpAgeStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/fswpAgeStatusTR' : 'https://northern-pathways-backend.onrender.com/api/fswpAgeStatus';
        const additionalStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/additionalStatusTR' : 'https://northern-pathways-backend.onrender.com/api/additionalStatus';
        
        const caEducationEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/caEducationStatusTR' : 'https://northern-pathways-backend.onrender.com/api/caEducationStatus';
        const caEducationLevelEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/caEducationLevelStatusTR' : 'https://northern-pathways-backend.onrender.com/api/caEducationLevelStatus';
        const workStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/fswpCaWorkStatusTR' : 'https://northern-pathways-backend.onrender.com/api/fswpCaWorkStatus';
        

        const maritalEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/maritalStatusTR' : 'https://northern-pathways-backend.onrender.com/api/maritalStatus';
        const partnerEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/partnerStatusTR' : 'https://northern-pathways-backend.onrender.com/api/partnerStatus';
        const partnerMovingEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/movingStatusTR' : 'https://northern-pathways-backend.onrender.com/api/movingStatus';
        const lawPartnerStatusEndpoint = selectedLanguage === 'tr' ? 'https://northern-pathways-backend.onrender.com/api/lawPartnerStatusTR' : 'https://northern-pathways-backend.onrender.com/api/lawPartnerStatus';

        axios.get(languageStatusEndpoint)
            .then(response => {
                const sortedLanguageStatusOptions = rearrangeOptions(response.data, 1);
                setLanguageStatusOptions(sortedLanguageStatusOptions);
            })
            .catch(error => {
                console.error('Error fetching language status:', error);
            });

        axios.get(languageTestEndpoint)
            .then(response => {
                const sortedLanguageTestOptions = rearrangeOptions(response.data, 1);
                setLanguageTestOptions(sortedLanguageTestOptions);
                const sortedSpouseLanguageTestOptions = rearrangeOptions(response.data, 1);
                setSpouseLanguageTestOptions(sortedSpouseLanguageTestOptions);
            })
            .catch(error => {
                console.error('Error fetching language test options:', error);
            });
        
        axios.get(educationEndpoint)
            .then(response => {
                const sortedEducationOptions = rearrangeOptions(response.data, 1);
                setEducationOptions(sortedEducationOptions);
                const sortedSpouseEducationStatusOptions = rearrangeOptions(response.data, 1);
                setSpouseEducationStatusOptions(sortedSpouseEducationStatusOptions);
            })
            .catch(error => {
                console.error('Error fetching education status:', error);
            });

        axios.get(fswpWorkStatusEndpoint)
            .then(response => {
                const sortedWorkOptions = rearrangeOptions(response.data, 1);
                setFSWPWorkOptions(sortedWorkOptions);
            })
            .catch(error => {
                console.error('Error fetching work status:', error);
            });

        axios.get(fswpAgeStatusEndpoint)
            .then(response => {
                const sortedAgeOptions = rearrangeOptions(response.data, 1);
                setFSWPAgeOptions(sortedAgeOptions);
            })
            .catch(error => {
                console.error('Error fetching age status:', error);
            });

        axios.get(additionalStatusEndpoint)
            .then(response => {
                const sortedAdditionalStatusOptions = rearrangeOptions(response.data, 1);
                setAdditionalStatusOptions(sortedAdditionalStatusOptions);
            })
            .catch(error => {
                console.error('Error fetching additional status:', error);
            });


        axios.get(caEducationEndpoint)
            .then(response => {
              const sortedCaEducationOptions = rearrangeOptions(response.data, 1);
              setCaEducationOptions(sortedCaEducationOptions);
            })
            .catch(error => {
              console.error('Error fetching CA education status:', error);
            });
      
        axios.get(workStatusEndpoint)
            .then(response => {
                const sortedWorkStatusOptions = rearrangeOptions(response.data, 1);
                setWorkStatusOptions(sortedWorkStatusOptions);
                const sortedSpouseWorkStatusOptions = rearrangeOptions(response.data, 1);
                setSpouseWorkStatusOptions(sortedSpouseWorkStatusOptions);
            })
            .catch(error => {
                console.error('Error fetching work status:', error);
            });

        axios.get(maritalEndpoint)
            .then(response => {
                const sortedMaritalOptions = rearrangeOptions(response.data, 1);
                setMaritalOptions(sortedMaritalOptions);
            })
            .catch(error => {
                console.error('Error fetching marital status:', error);
            });

        axios.get(partnerEndpoint)
            .then(response => {
                const sortedPartnerOptions = rearrangeOptions(response.data, 1);
                setPartnerOptions(sortedPartnerOptions);
            })
            .catch(error => {
                console.error('Error fetching partner status:', error);
            });

        axios.get(partnerMovingEndpoint)
            .then(response => {
                const sortedPartnerMovingOptions = rearrangeOptions(response.data, 1);
                setPartnerMovingOptions(sortedPartnerMovingOptions);
            })
            .catch(error => {
                console.error('Error fetching partner moving status:', error);
            });

        axios.get(lawPartnerStatusEndpoint)
            .then(response => {
                const sortedLawPartnerStatusOptions = rearrangeOptions(response.data, 1);
                setLawPartnerStatusOptions(sortedLawPartnerStatusOptions);
            })
            .catch(error => {
                console.error('Error fetching law partner status:', error);
            });

    }, [localStorage.getItem('selectedLanguage')]);

    return (
        <div className='flex flex-row justify-center min-h-screen'>
            <div className='flex'>
                <LegalDisclaimerFswp />
            </div>
            <div className='mt-4 mb-4 flex flex-col w-3/4 max-[960px]:w-full border shadow-sm shadow-black rounded-xl border-gray-300'>
                <div className='flex w-[100%] mt-8 flex-row items-center justify-center'>
                    <div className='flex flex-col items-center justify-center'>
                        <h1 className='text-xl font-bold'>{t("FSWPTitle")}</h1>
                        <h1 className='text-xl font-bold'>{t("FSWPTitle2")}</h1>
                    </div>
                </div>

                <div className='flex justify-center w-full border-red-500 '>
                    <button
                        className='w-full mt-0 mb-2 italic text-gray-700 underline origin-bottom-right bg-white border-none rounded-lg'
                        onClick={handleButtonClick}
                    >
                        {t("FSWPPopUpQuestion")}
                    </button>
                </div>

                <div className='flex w-[90%] flex-col mb-8 mr-4 ml-4'>
                    <ol className='list-decimal'>
                        <li className=''>
                            <p>{t("languageStatusDescription")}</p>
                            <p className='mt-2' style={{textDecoration: "underline"}}><i>{t("languageStatusWarning")}</i></p>
                            <p className='mt-2 font-bold text-red-500 underline text-md'>{t("FSWPLanguageNote")}</p>
                            <ul className='mt-2 list-disc'>
                                <li>
                                    <p>{t("languageQuestionFirst")}</p>
                                    <select className="selection" id="language-test" value={languageTest} onChange={handleLanguageTest}>
                                        <option value="" disabled>{t("select")}</option>
                                        {languageTestOptions.slice(0, -1).map(option => 
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        )}
                                    </select>                  
                                </li>
                            </ul>

                            {showLanguageTestQuestion && (
                            <ul className='ml-8 list-disc test'>
                                <li className='mt-4'>
                                    <p>{t("speaking")}:</p>
                                    <select className="selection" id="CELPIP-G-speaking" value={speakingTest} onChange={handleSpeaking}>
                                        <option value="" disabled>{t("select")}</option>
                                            {testPoints.speaking && testPoints.speaking.slice(0, 4).map((point, index) => (
                                            <option key={index} value={point}>
                                                {point}
                                            </option>
                                        ))}
                                    </select>
                                </li>

                                <li className='mt-4'>
                                    <p>{t("listening")}:</p>
                                    <select className="selection" id="CELPIP-G-listening" value={listeningTest} onChange={handleListening}>
                                        <option value="" disabled>{t("select")}</option>
                                        {testPoints.listening && testPoints.listening.slice(0, 4).map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                    </select>
                                </li>

                                <li className='mt-4'>
                                    <p>{t("reading")}:</p>
                                    <select className="selection" id="CELPIP-G-reading" value={readingTest} onChange={handleReading}>
                                        <option value="" disabled>{t("select")}</option>
                                        {testPoints.reading && testPoints.reading.slice(0, 4).map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                    </select>
                                </li>

                                <li className='mt-4'>
                                    <p>{t("writing")}:</p>
                                    <select className="selection" id="CELPIP-G-writing" value={writingTest} onChange={handleWriting}>
                                        <option value="" disabled>{t("select")}</option>
                                        {testPoints.writing && testPoints.writing.slice(0, 4).map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                    </select>
                                </li>
                            </ul> 
                            )}

                            {showSecondLanguageTest && (
                            <ul className='mt-4 list-disc'>
                                <li>
                                    <p>{t("languageQuestionSecond")}</p>
                                    <p style={{textDecoration: "underline"}}><i>{t("languageStatusWarning")}</i></p>
                                    <select className="mt-4 selection" id="second-language-test" value={secondLanguageTest} onChange={handleSecondLanguageTest}>
                                        <option value="" disabled>{t("select")}</option>
                                        {languageTest === 'option1' || languageTest === 'option2' || languageTest === 'option3' ? (
                                        languageTestOptions.slice(3, 6).map(option => 
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        )
                                        ) : languageTest === 'option4' || languageTest === 'option5' ? (
                                        [
                                            ...(languageTestOptions[0] ? [{ key: languageTestOptions[0].value, value: languageTestOptions[0].value, label: languageTestOptions[0].label }] : []),
                                            ...(languageTestOptions[1] ? [{ key: languageTestOptions[1].value, value: languageTestOptions[1].value, label: languageTestOptions[1].label }] : []),
                                            ...(languageTestOptions[1] ? [{ key: languageTestOptions[2].value, value: languageTestOptions[2].value, label: languageTestOptions[2].label }] : []),
                                            ...(languageTestOptions[5] ? [{ key: languageTestOptions[5].value, value: languageTestOptions[5].value, label: languageTestOptions[5].label }] : [])
                                        ].map(option => 
                                            <option key={option.key} value={option.value}>{option.label}</option>
                                        )
                                        ) : null}
                                    </select>
                                </li>
                                

                                {showSecondLanguageTestQuestion && (
                                <ul className='ml-8 list-disc'>
                                    <li className='mt-4'>
                                        <p>{t("speaking")}:</p>
                                        <select className="selection" id="CELPIP-G-speaking" value={speakingTestSecond} onChange={handleSecondSpeaking}>
                                        <option value="" disabled>{t("select")}</option>
                                        {secondTestPoints.speaking && secondTestPoints.speaking.map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                        </select>
                                    </li>

                                    <li className='mt-4'>
                                        <p>{t("listening")}:</p>
                                        <select className="selection" id="CELPIP-G-listening" value={listeningTestSecond} onChange={handleSecondListening}>
                                        <option value="" disabled>{t("select")}</option>
                                        {secondTestPoints.listening && secondTestPoints.listening.map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                        </select>
                                    </li>

                                    <li className='mt-4'>
                                        <p>{t("reading")}:</p>
                                        <select className="selection" id="CELPIP-G-reading" value={readingTestSecond} onChange={handleSecondReading}>
                                        <option value="" disabled>{t("select")}</option>
                                        {secondTestPoints.reading && secondTestPoints.reading.map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                        </select>
                                    </li>

                                    <li className='mt-4'>
                                        <p>{t("writing")}:</p>
                                        <select className="selection" id="CELPIP-G-writing" value={writingTestSecond} onChange={handleSecondWriting}>
                                        <option value="" disabled>{t("select")}</option>
                                        {secondTestPoints.writing && secondTestPoints.writing.map((point, index) => (
                                            <option key={index} value={point}>
                                            {point}
                                            </option>
                                        ))}
                                        </select>
                                    </li>
                                </ul>
                                )}
                             </ul>
                             )}
                        </li>

                        {showEducationStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("educationStatusQuestion")}</p>
                            <ul className='mt-2 list-disc'>
                                <li>{t("educationStatusElibilityLi1")}</li>
                                <li>{t("educationStatusElibilityLi2")}</li>
                            </ul>
                            <select className="selection-education" id="education-status" value={educationStatus} onChange={handleEducationStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {educationOptions.slice(1, 8).map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showFSWPWorkStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("FSWPWorkExperience")}</p>
                            <p className='mt-2'>{t("FSWPWorkExperienceP1")}</p>
                            <ul className='list-disc'>
                                <p>{t("FSWPWorkExperienceLiP1")}</p>
                                <li>{t("FSWPWorkExperienceLi")}</li>
                                <li>{t("FSWPWorkExperienceLi2")}</li>
                                <li>{t("FSWPWorkExperienceLi3")}</li>
                            </ul>
                            <select className="selection" id="work-status" value={FSWPWorkStatus} onChange={handleFSWPWorkStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {FSWPWorkOptions.slice(1, 5).map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showFSWPAgeStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("ageQuestion")}</p>
                            <select className="selection" id="age-status" value={FSWPAgeStatus} onChange={handleFSWPAgeStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {FSWPAgeOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showAdditionalStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("additionalPointDescription")} 
                                <a
                                    href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/offer-employment/lmia-exempt.html "
                                    className="font-bold text-blue-400 underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    ({t("additionalPointDescriptionNeeded")}) 
                                </a>{" "} 
                            </p> 
                            <p>
                                {t("additionalPointQualification")} 
                                <a
                                    href="https://www.northernpathways.ca/pre-assessment-form"
                                    className="font-bold text-blue-400 underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("additionalPointQualificationBold")} 
                                </a>{" "} 
                                {t("additionalPointQualificationCont")}
                            </p>
                            <select className="mt-2 selection" id="additional-points" value={additionalStatus} onChange={handleAdditionalStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {additionalStatusOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showTitle && (
                        <h1 className='mt-4 ml-4 text-xl font-extrabold'>{t("FSWPAdaptability")}</h1>
                        )}

                        {showCaEducationStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("FSWPEducation")}</p>
                            <select className="selection" id="ca-education-status" value={caEducationStatus} onChange={handleCaEducationStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {caEducationOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showWorkStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("FSWPCanadianWorkExp")}</p>
                            <p className='mt-2'>{t("FSWPCanadianWorkP1")}</p>
                            <select className="selection" id="work-status" value={workStatus} onChange={handleWorkStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {workStatusOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}
              
                        {showMaritalStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("maritalStatusQuestion")}</p>
                            <select className="selection" id="marital-status" value={maritalStatus} onChange={handleMaritalChange}>
                                <option value="" disabled>{t("select")}</option>
                                {maritalOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </li>
                        )}

                        {showSpouseQuestion && (
                        <li className='mt-4'>
                            <p>{t("spouseQuestion")}</p>
                            <select className="selection" id="partner-status" value={partnerStatus} onChange={handlePartnerStatus}>
                                <option value="" disabled>{t("select")}</option>
                                {partnerOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>

                            {showSpouseMovingQuestion && (
                            <ul className='list-disc'>
                                <li>
                                    <p>{t("spouseMovingQuestion")}</p>
                                    <select className="selection" id="moving-status" value={movingStatus} onChange={handleMovingStatus}>
                                        <option value="" disabled>{t("select")}</option>
                                        {partnerMovingOptions.map(option => 
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        )}
                                    </select>
                                </li>
                            </ul>
                            )}
                        </li>
                        )}

                        {showSpouseEducationStatus && (
                        <li className='mt-4'>
                            <p>{t("FSWPSpouseEducationP1")}</p>
                            <select className="selection" id="spouseEducation-status" value={spouseEducationStatus} onChange={handleSpouseEducationStatus}>
                                <option value="" disabled>{t("select")}</option>
                                    {partnerMovingOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showSpouseWorkStatus && (
                        <li className='mt-4'>
                            <p>{t("spouseWorkStatus")}</p>
                            <select className="selection" id="spouseWork-status" value={spouseWorkStatus} onChange={handleSpouseWorkStatus}>
                                <option value="" disabled>{t("select")}</option>
                                    {spouseWorkStatusOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                            </select>
                        </li>
                        )}

                        {showSpouseLanguageTest && (
                        <li className='mt-4'>
                            <p>{t("spouseLanguageTestQuestion")}</p>
                            <p>{t("languageStatusDescription")}</p>
                            <p style={{textDecoration: "underline"}}><i>{t("languageStatusWarning")}</i></p>
                            <p>{t("languageQuestionSpouse")}</p>
                                <select className="selection" id="spouseLanguage-test" value={spouseLanguageTest} onChange={handleSpouseLanguageTest}>
                                    <option value="" disabled>{t("select")}</option>
                                    {spouseLanguageTestOptions.map(option => 
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                )}
                                </select>
            
                            {showSpouseLanguageTestQuestion && (
                            <ul className='list-disc'>
                                <li>
                                    <p>{t("speaking")}</p>
                                    <select className="selection" id="language-test" value={speakingTestSpouse} onChange={handleSpouseSpeaking}>
                                        <option value="" disabled>{t("select")}</option>
                                        {spouseTestPoints.speaking && spouseTestPoints.speaking.map((point, index) => (
                                        <option key={index} value={point}>
                                            {point}
                                        </option>
                                        ))}
                                    </select>
                                </li>

                                <li>
                                    <p>{t("listening")}:</p>
                                    <select className="selection" id="language-test" value={listeningTestSpouse} onChange={handleSpouseListening}>
                                        <option value="" disabled>{t("select")}</option>
                                        {spouseTestPoints.listening && spouseTestPoints.listening.map((point, index) => (
                                        <option key={index} value={point}>
                                            {point}
                                        </option>
                                        ))}
                                    </select>
                                </li>

                                <li>
                                    <p>{t("reading")}</p>
                                    <select className="selection" id="language-test" value={readingTestSpouse} onChange={handleSpouseReading}>
                                        <option value="" disabled>{t("select")}</option>
                                        {spouseTestPoints.reading && spouseTestPoints.reading.map((point, index) => (
                                        <option key={index} value={point}>
                                            {point}
                                        </option>
                                        ))}
                                    </select>
                                </li>

                                <li>
                                    <p>{t("writing")}</p>
                                    <select className="selection" id="language-test" value={writingTestSpouse} onChange={handleSpouseWriting}>
                                        <option value="" disabled>{t("select")}</option>
                                        {spouseTestPoints.writing && spouseTestPoints.writing.map((point, index) => (
                                        <option key={index} value={point}>
                                            {point}
                                        </option>
                                        ))}
                                    </select>
                                </li>
                            </ul>
                            )}
                        </li>
                        )}

                        {showLawPartnerStatusQuestion && (
                        <li className='mt-4'>
                            <p>{t("FSWPRelativesP1")}</p>
                            <ul className='list-disc'>
                                <li>{t("FSWPRelativesLi1")}</li>
                                <li>{t("FSWPRelativesLi2")}</li>
                                <li>{t("FSWPRelativesLi3")}</li>
                                <li>{t("FSWPRelativesLi4")}</li>
                                <li>{t("FSWPRelativesLi5")}</li>
                                <li>{t("FSWPRelativesLi6")}</li>
                                <li>{t("FSWPRelativesLi7")}</li>
                            </ul>
                                <select className="selection" id="lawPartner-status" value={lawPartnerStatus} onChange={handleLawPartnerStatus}>
                                    <option value="" disabled>{t("select")}</option>
                                    {lawPartnerStatusOptions.map(option => 
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    )}
                                </select>
                        </li>
                        )}
                    </ol>

                    <FSWPScoreCalculator
                        languageTest={languageTest}
                        speakingTest={speakingTest}
                        listeningTest={listeningTest}
                        readingTest={readingTest}
                        writingTest={writingTest}
                        secondLanguageTest={secondLanguageTest}
                        speakingTestSecond={speakingTestSecond}
                        listeningTestSecond={listeningTestSecond}
                        readingTestSecond={readingTestSecond}
                        writingTestSecond={writingTestSecond}
                        educationStatus={educationStatus}
                        FSWPWorkStatus={FSWPWorkStatus}
                        FSWPAgeStatus={FSWPAgeStatus}
                        additionalStatus={additionalStatus}
                        caEducationStatus={caEducationStatus}
                        workStatus={workStatus}
                        maritalStatus={maritalStatus}
                        partnerStatus={partnerStatus}
                        movingStatus={movingStatus}
                        spouseEducationStatus={spouseEducationStatus}
                        spouseWorkStatus={spouseWorkStatus}
                        spouseLanguageTest={spouseLanguageTest}
                        speakingTestSpouse={speakingTestSpouse}
                        listeningTestSpouse={listeningTestSpouse}
                        readingTestSpouse={readingTestSpouse}
                        writingTestSpouse={writingTestSpouse}
                        lawPartnerStatus={lawPartnerStatus}
                    />
                </div>
            </div>

            {showPopup && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg  w-auto max-w-xl max-h-[90vh] overflow-y-auto popup">
                        <div className='flex flex-row justify-between'>
                            <h2 className="mb-4 text-xl font-bold">{t("FSWPPopUpTitle")}</h2>
                            <button className="absolute top-0 w-2 h-2 text-gray-500 bg-transparent border-none right-4" onClick={handleCloseClick}>
                                <FaTimes />
                            </button>                    
                        </div>
                        <p>
                            {t("FSWPPopUpP1")} 
                            <a
                                href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/federal-skilled-workers.html"
                                className="font-bold text-blue-400 underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("FSWPPopUpP1Link")} 
                            </a>{" "} 
                            {t("FSWPPopUpP1Cont")}
                        </p>
                        <p className="mt-2">
                            {t("FSWPPopUpP2")}
                        </p>
                        <p className="mt-2">
                            {t("FSWPPopUpP3")}{" "}
                            <span className="font-bold">
                                {t("FSWPPopUpP3Bold")}
                            </span>{" "}
                            {t("FSWPPopUpP3Cont")}
                        </p>
                        <p className="mt-2">
                            {t("FSWPPopUpP4")}{" "}
                            <span className="font-bold">
                                {t("FSWPPopUpP4Bold")}
                            </span>{" "}
                            {t("FSWPPopUpP4Cont")}
                        </p>
                        <h3 className="mt-4 text-lg font-semibold">{t("FSWPPopUpTitle2")}</h3>
                        <p>{t("FSWPPopUpP5")}</p>
                        <ul className="mt-2 ml-5 list-disc">
                            <li><span className='font-bold'>{t("FSWPPopUpLi1Title")}</span> {t("FSWPPopUpLi1")}</li>
                            <li><span className='font-bold'>{t("FSWPPopUpLi2Title")}</span> {t("FSWPPopUpLi2")}</li>
                            <li><span className='font-bold'>{t("FSWPPopUpLi3Title")}</span> {t("FSWPPopUpLi3")}</li>
                            <li><span className='font-bold'>{t("FSWPPopUpLi4Title")}</span> {t("FSWPPopUpLi4")}</li>
                            <li><span className='font-bold'>{t("FSWPPopUpLi5Title")}</span> {t("FSWPPopUpLi5")}</li>
                            <li><span className='font-bold'>{t("FSWPPopUpLi6Title")}</span> {t("FSWPPopUpLi6")}</li>
                        </ul>
                        <p className="mt-2">
                            {t("FSWPPopUpP6")} 
                            <span className="font-bold">
                                {t("FSWPPopUpP6Bold")} 
                            </span>{" "}
                            {t("FSWPPopUpP6Cont")}
                        </p>
                        <p className="mt-2">
                            {t("FSWPPopUpP7")}
                        </p>
                        <p className="mt-2">
                            {t("FSWPPopUpP8")} 
                            <a
                                href="https://northern-pathways-backend.onrender.com/crstool"
                                className="font-bold text-blue-400 underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("FSWPPopUpP8Bold")}
                            </a>{" "} 
                            {t("FSWPPopUpP8Cont")}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FSWPTool;
