import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Document, Page, Text, PDFDownloadLink, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import {Table, TR, TH, TD} from '@ag-media/react-pdf-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FaTimes } from 'react-icons/fa';
import './css/scoreCalculator.css';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight } from 'react-icons/ai';

import backgroundImage from '../images/template.png'; // Adjust the path as needed

const FSWPScoreCalculator = ({ languageTest, speakingTest, listeningTest, readingTest, writingTest, secondLanguageTest, speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, educationStatus, FSWPWorkStatus, FSWPAgeStatus, additionalStatus, caEducationStatus, workStatus, maritalStatus, partnerStatus, movingStatus, spouseEducationStatus, spouseWorkStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse, lawPartnerStatus }) => {
    const { t } = useTranslation();

    const [showResults, setShowResults] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const popUpAnimation = useSpring({
        opacity: showResults ? 1 : 0,
        transform: showResults ? 'translateY(0)' : 'translateY(-50%)',
    });

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const [totalPointSections, setTotalPointSections] = useState(0);

    useEffect(() => {
        const buttonChecker = () => {
            if (maritalStatus === 'option2' || maritalStatus === 'option3') {
                if (partnerStatus === 'option2') {
                    if (lawPartnerStatus !== '') {
                        return true;
                    }
                } else if (partnerStatus === 'option1') {
                    if (movingStatus === 'option1') {
                        if (lawPartnerStatus !== '') {
                            return true;
                        }
                    } else {
                        if (spouseEducationStatus !== '' && spouseWorkStatus !== '') {
                            if (spouseLanguageTest === 'option6') {
                                if (lawPartnerStatus !== '') {
                                    return true;
                                }
                            } else {
                                if (speakingTestSpouse !== '' && listeningTestSpouse !== '' && readingTestSpouse !== '' && writingTestSpouse !== '' && lawPartnerStatus !== '') {
                                    return true;
                                }
                            }
                        }
                    }
                }
            } else {
                if (lawPartnerStatus !== '') {
                    return true;
                }
            }
            
            return false;
        };
    
        const visibility = buttonChecker();
        setIsButtonVisible(visibility);
    }, [languageTest, speakingTest, listeningTest, readingTest, writingTest, secondLanguageTest, speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, educationStatus, FSWPWorkStatus, FSWPAgeStatus, additionalStatus, caEducationStatus, workStatus, maritalStatus, partnerStatus, movingStatus, spouseEducationStatus, spouseWorkStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse, lawPartnerStatus]);
    
    const FSWPpoints = {
        languageTest: {
            option1: {
                speaking: {
                    '10-12': 6,
                    '9': 6,
                    '8': 5,
                    '7': 4,
                    '6': 0,
                    '5': 0,
                    '4': 0,
                    'M 0-3': 0
                },
                listening: {
                    '10-12': 6,
                    '9': 6,
                    '8': 5,
                    '7': 4,
                    '6': 0,
                    '5': 0,
                    '4': 0,
                    'M 0-3': 0
                },
                reading: {
                    '10-12': 6,
                    '9': 6,
                    '8': 5,
                    '7': 4,
                    '6': 0,
                    '5': 0,
                    '4': 0,
                    'M 0-3': 0
                },
                writing: {
                    '10-12': 6,
                    '9': 6,
                    '8': 5,
                    '7': 4,
                    '6': 0,
                    '5': 0,
                    '4': 0,
                    'M 0-3': 0
                }
            },
            option2: {
                speaking: {
                    '7.5-9.0': 6,
                    '7.0': 6,
                    '6.5': 5,
                    '6.0': 4,
                    '5.5': 0,
                    '5.0': 0,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                },
                listening: {
                    '8.5-9.0': 6,
                    '8.0': 6,
                    '7.5': 5,
                    '6.0-7.0': 4,
                    '5.5': 0,
                    '5.0': 0,
                    '4.5': 0,
                    '0-4.0': 0
                },
                reading: {
                    '8.0-9.0': 6,
                    '7.0-7.5': 6,
                    '6.5': 5,
                    '6.0': 4,
                    '5.0-5.5': 0,
                    '4.0-4.5': 0,
                    '3.5': 0,
                    '0-3.0': 0
                },
                writing: {
                    '7.5-9.0': 6,
                    '7.0': 6,
                    '6.5': 5,
                    '6.0': 4,
                    '5.5': 0,
                    '5.0': 0,
                    '4.0-4.5': 0,
                    '0-3.5': 0
                }
            },
            option3: {
                speaking: {
                    '89+': 6,
                    '84-88': 6,
                    '76-83': 5,
                    '68-75': 4,
                    '59-67': 0,
                    '51-58': 0,
                    '42-50': 0,
                    '0-41': 0
                },
                listening: {
                    '89+': 6,
                    '82-88': 6,
                    '71-81': 5,
                    '60-70': 4,
                    '50-59': 0,
                    '39-49': 0,
                    '28-38': 0,
                    '0-27': 0
                },
                reading: {
                    '88+': 6,
                    '78-87': 6,
                    '69-77': 5,
                    '60-68': 4,
                    '51-59': 0,
                    '42-50': 0,
                    '33-41': 0,
                    '0-32': 0
                },
                writing: {
                    '90+': 6,
                    '88-89': 6,
                    '79-87': 5,
                    '69-78': 4,
                    '60-68': 0,
                    '51-59': 0,
                    '41-50': 0,
                    '0-40': 0
                }
            },
            option4: {
                speaking: {
                    '556-699': 6,
                    '518-555': 6,
                    '494-517': 5,
                    '456-493': 4,
                    '422-455': 0,
                    '387-421': 0,
                    '328-386': 0
                },
                listening: {
                    '546-699': 6,
                    '503-545': 6,
                    '462-502': 5,
                    '434-461': 4,
                    '393-433': 0,
                    '352-392': 0,
                    '306-351': 0
                },
                reading: {
                    '546-699': 6,
                    '503-545': 6,
                    '462-502': 5,
                    '434-461': 4,
                    '393-433': 0,
                    '352-392': 0,
                    '306-351': 0
                },
                writing: {
                    '558-699': 6,
                    '512-557': 6,
                    '472-511': 5,
                    '428-471': 4,
                    '379-427': 0,
                    '330-378': 0,
                    '268-329': 0
                }
            },
            option5: {
                speaking: {
                    '16-20': 6,
                    '14-15': 6,
                    '12-13': 5,
                    '10-11': 4,
                    '7-9': 0,
                    '6': 0,
                    '4-5': 0,
                    '0-3': 0
                },
                listening: {
                    '549-699': 6,
                    '523-548': 6,
                    '503-522': 5,
                    '458-502': 4,
                    '398-457': 0,
                    '369-397': 0,
                    '331-368': 0,
                    '0-330': 0
                },
                reading: {
                    '549-699': 6,
                    '524-548': 6,
                    '499-523': 5,
                    '453-498': 4,
                    '406-452': 0,
                    '375-405': 0,
                    '342-374': 0,
                    '0-341': 0
                },
                writing: {
                    '16-20': 6,
                    '14-15': 6,
                    '12-13': 5,
                    '10-11': 4,
                    '7-9': 0,
                    '6': 0,
                    '4-5': 0,
                    '0-3': 0
                }
            },
        },
        secondLanguageTest: {
            false: 0,
            true: 4
        },
        educationStatus: {
            option1: 0,
            option2: 5,
            option3: 15,
            option4: 19,
            option5: 21,
            option6: 22,
            option7: 23,
            option8: 25,
        },
        FSWPWorkStatus: {
            option1: 0,
            option2: 9,
            option3: 11,
            option4: 13,
            option5: 15
        },
        FSWPAgeStatus: {
            option1: 0,
            option2: 12,
            option3: 11,
            option4: 10,
            option5: 9,
            option6: 8,
            option7: 7,
            option8: 6,
            option9: 5,
            option10: 4,
            option11: 3,
            option12: 2,
            option13: 1,
            option14: 0
        },
        employment: {
            option1: 0,
            option2: 10
        },
        caEducationStatus: {
            option1: 0,
            option2: 5
        },
        workStatus: {
            option1: 0,
            option2: 10,
            option3: 10,
            option4: 10,
            option5: 10,
            option6: 10
        },
        spouseEducationStatus: {
            option1: 0,
            option2: 5
        },
        spouseWorkStatus: {
            option1: 0,
            option2: 5,
            option3: 5,
            option4: 5,
            option5: 5,
            option6: 5
        },
        spouseLanguageStatus: {
            false: 0,
            true: 5
        },
        lawPartnerStatus: {
            option1: 0,
            option2: 5
        }
    }

    const [speakingPoint, setSpeakingPoint] = useState(0);
    const [listeningPoint, setListeningPoint] = useState(0);
    const [readingPoint, setReadingPoint] = useState(0);
    const [writingPoint, setWritingPoint] = useState(0);

    const [totalLanguage, setTotalLanguage] = useState(0);

    const [educationPoint, setEducationPoint] = useState(0);
    const [FSWPWorkPoint, setFSWPWorkPoint] = useState(0);
    const [FSWPAgePoint, setFSWPAgePoint] = useState(0);
    const [additionalPoint, setAdditionalPoint] = useState(0);

    const [secondLanguagePoint, setSecondLanguagePoint] = useState(0);


    const [canadaPostSecondaryPoint, setCanadaPostSecondaryPoint] = useState(0);
    const [canadaWorkExperiencePoint, setCanadaWorkExperiencePoint] = useState(0);
    const [spouseEducationPoint, setSpouseEducationPoint] = useState(0);
    const [spouseWorkExperiencePoint, setSpouseWorkExperiencePoint] = useState(0);
    const [spouseLanguagePoint, setSpouseLanguagePoint] = useState(0);
    const [lawPartnerPoint, setLawPartnerPoint] = useState(0);

    const [firstPartTotal, setFirstPartTotal] = useState(0);
    const [additionalPointTotal, setAdditionalPointTotal] = useState(0);

    const [clickedOnce, setClickedOnce] = useState(false);

    useEffect(() => {
        if (FSWPpoints && FSWPpoints.languageTest && FSWPpoints.educationStatus && FSWPpoints.FSWPWorkStatus && FSWPpoints.FSWPAgeStatus && FSWPpoints.employment) {
            let speakingPoint = 0, listeningPoint = 0, readingPoint = 0, writingPoint = 0;
            
            if (languageTest && FSWPpoints.languageTest[languageTest]) {
                speakingPoint = FSWPpoints.languageTest[languageTest].speaking[speakingTest] || 0;
                listeningPoint = FSWPpoints.languageTest[languageTest].listening[listeningTest] || 0;
                readingPoint = FSWPpoints.languageTest[languageTest].reading[readingTest] || 0;
                writingPoint = FSWPpoints.languageTest[languageTest].writing[writingTest] || 0;
            }

            let educationPoint = FSWPpoints.educationStatus[educationStatus] || 0;
            let FSWPWorkPoint = FSWPpoints.FSWPWorkStatus[FSWPWorkStatus] || 0;
            let FSWPAgePoint = FSWPpoints.FSWPAgeStatus[FSWPAgeStatus] || 0;
            let additionalPoint = FSWPpoints.employment[additionalStatus] || 0;

            setSpeakingPoint(speakingPoint);
            setListeningPoint(listeningPoint);
            setReadingPoint(readingPoint);
            setWritingPoint(writingPoint);
            setTotalLanguage(speakingPoint + listeningPoint + readingPoint + writingPoint);
            setEducationPoint(educationPoint);
            setFSWPWorkPoint(FSWPWorkPoint);
            setFSWPAgePoint(FSWPAgePoint);
            setAdditionalPoint(additionalPoint);

            console.log("Speaking:", speakingPoint);
            console.log("Listening:", listeningPoint);
            console.log("Reading:", readingPoint);
            console.log("Writing:", writingPoint);
            console.log("Education:", educationPoint);
            console.log("FSWPWorkPoint:", FSWPWorkPoint);
            console.log("FSWPAgePoint:", FSWPAgePoint);
            console.log("additionalPoint:", additionalPoint);
            
            setShowResults(false);
        }
    }, [
        languageTest, speakingTest, listeningTest, readingTest, writingTest,
        secondLanguageTest, speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond,
        educationStatus, FSWPWorkStatus, FSWPAgeStatus, additionalStatus, caEducationStatus,
        workStatus, maritalStatus, partnerStatus, movingStatus, spouseEducationStatus,
        spouseWorkStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse,
        readingTestSpouse, writingTestSpouse, lawPartnerStatus
    ]);


    const calculateAdditionalPoints = () => {
        let canadaPostSecondaryPoint = caEducationStatus === 'option2' ? FSWPpoints.caEducationStatus[caEducationStatus] : 0;
        let canadaWorkExperiencePoint = (workStatus === 'option1' || workStatus === '') ? 0 : FSWPpoints.workStatus[workStatus];
        let spouseEducationPoint = (spouseEducationStatus === 'option2') ? FSWPpoints.spouseEducationStatus[spouseEducationStatus] : 0;
        let spouseWorkExperiencePoint = (spouseWorkStatus === 'option1' || spouseWorkStatus === '') ? 0 : FSWPpoints.spouseWorkStatus[spouseWorkStatus];
        let lawPartnerPoint = (lawPartnerStatus === 'option2') ? FSWPpoints.lawPartnerStatus[lawPartnerStatus] : 0;
    
        setCanadaPostSecondaryPoint(prevCanadaPostSecondaryPoint => {
            if (caEducationStatus === 'option1' || caEducationStatus === '') return 0;
            const newPoint = prevCanadaPostSecondaryPoint + canadaPostSecondaryPoint;
            return Math.min(newPoint, 5); // Set max to 5
        });
        setCanadaWorkExperiencePoint(prevCanadaWorkExperiencePoint => {
            if (workStatus === 'option1' || workStatus === '') return 0;
            const newPoint = prevCanadaWorkExperiencePoint + canadaWorkExperiencePoint;
            return Math.min(newPoint, 10); // Set max to 10
        });
        setSpouseEducationPoint(prevSpouseEducationPoint => {
            if (spouseEducationStatus === 'option1' || spouseEducationStatus === '') return 0;
            const newPoint = prevSpouseEducationPoint + spouseEducationPoint;
            return Math.min(newPoint, 5); // Set max to 5
        });
        setSpouseWorkExperiencePoint(prevSpouseWorkExperiencePoint => {
            if (spouseWorkStatus === 'option1' || spouseWorkStatus === '') return 0;
            const newPoint = prevSpouseWorkExperiencePoint + spouseWorkExperiencePoint;
            return Math.min(newPoint, 5); // Set max to 5
        });
        setLawPartnerPoint(prevLawPartnerPoint => {
            if (lawPartnerStatus === 'option1' || lawPartnerStatus === '') return 0;
            const newPoint = prevLawPartnerPoint + lawPartnerPoint;
            return Math.min(newPoint, 5); // Set max to 5
        });
    };
    
    

    const speakingCLB5Array = [
        '5',
        '6',
        '5.0',
        '5.5',
        '51-58',
        '59-67',
        '387-421',
        '456-493',
        '6',
        '7-9',
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0',
        '7.5-9.0',
        '68-75',
        '76-83',
        '84-88',
        '89+',
        '456-493',
        '494-517',
        '518-555',
        '556-699',
        '10-11',
        '12-13',
        '14-15',
        '16-20'
    ];

    const listeningCLB5Array = [
        '5',
        '6',
        '5.0',
        '5.5',
        '39-48',
        '50-59',
        '352-392',
        '393-433',
        '369-397',
        '398-457',
        '7',
        '8',
        '9',
        '10-12',
        '6.0-7.0',
        '7.5',
        '8.0',
        '8.5-9.0',
        '60-70',
        '71-81',
        '82-88',
        '89+',
        '434-461',
        '462-502',
        '503-545',
        '546-699',
        '458-502',
        '503-522',
        '523-548',
        '549-699'
    ];

    const readingCLB5Array = [
        '5',
        '6',
        '4.0-4.5',
        '5.0-5.5',
        '42-50',
        '51-59',
        '352-392',
        '393-433',
        '375-405',
        '406-452',
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0-7.5',
        '8.0-9.0',
        '60-68',
        '69-77',
        '78-87',
        '88+',
        '434-461',
        '462-502',
        '503-545',
        '546-699',
        '453-498',
        '499-523',
        '524-548',
        '549-699'
    ];

    const writingCLB5Array = [
        '5',
        '6',
        '5.0',
        '5.5',
        '51-59',
        '60-68',
        '330-378',
        '379-427',
        '6',
        '7-9',
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0',
        '7.5-9.0',
        '69-78',
        '79-87',
        '88-89',
        '90+',
        '428-471',
        '472-511',
        '512-557',
        '558-699',
        '10-11',
        '12-13',
        '14-15',
        '16-20'
    ];

    const speakingCLB4Array = [
        '4',
        '5',
        '6',
        '4.0-4.5',
        '5.0',
        '5.5',
        '42-50',
        '51-58',
        '59-67',
        '328-386',
        '387-421',
        '456-493',
        '4-5',
        '6',
        '7-9',
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0',
        '7.5-9.0',
        '68-75',
        '76-83',
        '84-88',
        '89+',
        '456-493',
        '494-517',
        '518-555',
        '556-699',
        '10-11',
        '12-13',
        '14-15',
        '16-20'
    ];

    const listeningCLB4Array = [
        '4',
        '5',
        '6',
        '4.5',
        '5.0',
        '5.5',
        '28-38',
        '39-48',
        '50-59',
        '306-351',
        '352-392',
        '393-433',
        '331-368',
        '369-397',
        '398-457',
        '7',
        '8',
        '9',
        '10-12',
        '6.0-7.0',
        '7.5',
        '8.0',
        '8.5-9.0',
        '60-70',
        '71-81',
        '82-88',
        '89+',
        '434-461',
        '462-502',
        '503-545',
        '546-699',
        '458-502',
        '503-522',
        '523-548',
        '549-699'
    ];

    const readingCLB4Array = [
        '4',
        '5',
        '6',
        '3.5',
        '4.0-4.5',
        '5.0-5.5',
        '33-41',
        '42-50',
        '51-59',
        '306-351',
        '352-392',
        '393-433',
        '342-374',
        '375-405',
        '406-452',
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0-7.5',
        '8.0-9.0',
        '60-68',
        '69-77',
        '78-87',
        '88+',
        '434-461',
        '462-502',
        '503-545',
        '546-699',
        '453-498',
        '499-523',
        '524-548',
        '549-699'
    ];

    const writingCLB4Array = [
        '4',
        '5',
        '6',
        '4.0-4.5',
        '5.0',
        '5.5',
        '41-50',
        '51-59',
        '60-68',
        '268-329',
        '330-378',
        '379-427',
        '4-5',
        '6',
        '7-9',
        '7',
        '8',
        '9',
        '10-12',
        '6.0',
        '6.5',
        '7.0',
        '7.5-9.0',
        '69-78',
        '79-87',
        '88-89',
        '90+',
        '428-471',
        '472-511',
        '512-557',
        '558-699',
        '10-11',
        '12-13',
        '14-15',
        '16-20'
    ];

    const [CLB5Check, setCLB5Check] = useState(false);
    const [CLB4Check, setCLB4Check] = useState(false);

    useEffect(() => {
        if (speakingCLB5Array.includes(speakingTestSecond) && listeningCLB5Array.includes(listeningTestSecond) && readingCLB5Array.includes(readingTestSecond) && writingCLB5Array.includes(writingTestSecond)) {
            setCLB5Check(true);
            setSecondLanguagePoint(0);
            setSecondLanguagePoint(prevSecondLanguagePoint => prevSecondLanguagePoint + FSWPpoints.secondLanguageTest.true)
        } else {
            setCLB5Check(false);
            setCLB4Check(true);
            setSecondLanguagePoint(0);
        }
    }, [speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, secondLanguagePoint])

    useEffect(() => {
        if (speakingCLB4Array.includes(speakingTestSpouse) && listeningCLB4Array.includes(listeningTestSpouse) && readingCLB4Array.includes(readingTestSpouse) && writingCLB4Array.includes(writingTestSpouse)) {
            setCLB4Check(true);
            setSpouseLanguagePoint(prevSpouseLanguagePoint => prevSpouseLanguagePoint + FSWPpoints.spouseLanguageStatus.true)
        } else {
            setCLB4Check(false);
            setSpouseLanguagePoint(0);
        }
    }, [speakingTestSpouse, listeningTestSpouse, readingTestSpouse, writingTestSpouse])

    useEffect(() => {
        const total = totalLanguage + secondLanguagePoint + educationPoint + FSWPWorkPoint + FSWPAgePoint + additionalPoint;
        setFirstPartTotal(total);
    }, [speakingPoint, listeningPoint, readingPoint, writingPoint, secondLanguagePoint, educationPoint, FSWPWorkPoint, FSWPAgePoint, additionalPoint])

    useEffect(() => {
        const total = canadaPostSecondaryPoint + canadaWorkExperiencePoint + spouseEducationPoint + spouseLanguagePoint + lawPartnerPoint;
        setAdditionalPointTotal(total > 10 ? 10 : total);
    }, [canadaPostSecondaryPoint, canadaWorkExperiencePoint, spouseEducationPoint, spouseLanguagePoint, lawPartnerPoint]);
    
    const calculateScore = () => {
        calculateAdditionalPoints();
        setShowResults(true);
    };

    useEffect(() => {
        setShowResults(false);
        calculateAdditionalPoints();
    }, [
        languageTest, speakingTest, listeningTest, readingTest, writingTest, 
        secondLanguageTest, speakingTestSecond, listeningTestSecond, readingTestSecond, writingTestSecond, 
        educationStatus, FSWPWorkStatus, FSWPAgeStatus, additionalStatus, caEducationStatus, 
        workStatus, maritalStatus, partnerStatus, movingStatus, spouseEducationStatus, 
        spouseWorkStatus, spouseLanguageTest, speakingTestSpouse, listeningTestSpouse, 
        readingTestSpouse, writingTestSpouse, lawPartnerStatus
    ]);

    return (
        <div>
            {isButtonVisible && (
                <button className='mt-8 ml-8 bg-[#FF0000] text-white' onClick={calculateScore}>{t("calculate")}</button>
            )}

            <animated.div style={popUpAnimation}>
                {showResults && (
                    <div className='flex flex-row items-center ml-8'>
                        <p className='totalScore'>{t("totalScore")}: {firstPartTotal + additionalPointTotal}</p>
                        <button className="w-auto mt-4 ml-2 text-sm text-gray-500 underline bg-transparent border-none" onClick={toggleDetails}>
                            {showDetails ? t("detailHide") : t("detailShow")}
                        </button>
                    </div>
                )}
            </animated.div>

            <animated.div style={popUpAnimation}>
                {showResults && (
                    <div className='flex flex-col border-none shadow-[0_0_10px_rgba(255,0,0)] rounded-lg ml-8'>
                        <p className='mx-4 mt-4'>{t("callToAction")}</p>
                        <button className="flex border border-red-600 bg-[#FF0000] text-white items-center px-[20px] py-[12px] mx-2 my-1 mt-2 w-auto justify-center rounded-lg text-base" onClick={() => window.open("https://www.northernpathways.ca/pre-assessment-form", "_blank")} >
                            <AiOutlineArrowRight className='mr-2' size={24}/> {t("preAssessment")}
                        </button>
                    </div>
                )}
            </animated.div>

            {showDetails && (
            <div>
                <div className="overlay">
                    <div>
                        <button className="closeButton" onClick={toggleDetails}>
                            <FaTimes />
                        </button>                    
                    </div>
                    <div className="modal">
                        <div className="flex flex-col items-center justify-center w-full mb-8 border-none rounded-lg">
                            <div id="tableSectionA">
                                <h3 className='mb-2'>{t("FSWPGeneral")}</h3>

                                <table>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("firstLanguage")}</td>
                                        <td>{speakingPoint + listeningPoint + readingPoint + writingPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("secondLanguage")}</td>
                                        <td>{secondLanguagePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("education")}</td>
                                        <td>{educationPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("FSWPWorkExperience")}</td>
                                        <td>{FSWPWorkPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("age")}</td>
                                        <td>{FSWPAgePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("arrangedEmployment")}</td>
                                        <td>{additionalPoint}</td>
                                    </tr>
                                    <tr className='total-row'>
                                        <td>{t("FSWPGeneralTotal")}</td>
                                        <td>{firstPartTotal}</td>
                                    </tr>
                                </table>

                                <table>
                                    <h3 className='mt-2 mb-2'>{t("FSWPAdaptability")}</h3>
                                    <tr>
                                        <th>{t("category")}</th>
                                        <th>{t("points")}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("postSecondary")}</td>
                                        <td>{canadaPostSecondaryPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("caWork")}</td>
                                        <td>{canadaWorkExperiencePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("spouseEducation")}</td>
                                        <td>{spouseEducationPoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("spouseWorkExperience")}</td>
                                        <td>{spouseWorkExperiencePoint}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("spouseLanguageTotal")}</td>
                                        <td>{spouseLanguagePoint}</td>
                                    </tr>
                                    <tr className='total-row' style={{backgroundColor: '#ffacac'}}>
                                        <td>{t("FSWPAdaptabilityTotal")}</td>
                                        <td>{additionalPointTotal}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            )}
        </div>
    );
};

export default FSWPScoreCalculator;